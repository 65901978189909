import React from "react";
import StandardBlock from "../../../../components/standard-block/standard-block";
import CollapsibleBlock from "../../../../components/collapsible-block/collapsible-block";
import CollapsibleBlocksWrapper from "../../../../components/collapsible-block/collapsible-blocks-wrapper";
import ColBorder from "../../../../components/grid/col-border";

const OfferHero: React.FC<any> = () => {
  return (
    <div className="offer-hero">
      <StandardBlock variant={"white"}>
        <div className="sectionSubtitle">SPECJALIZACJE</div>
      </StandardBlock>
      <StandardBlock variant={"white"}>
        <ColBorder variant="white" fill="dark">
          <CollapsibleBlocksWrapper isAcordeon={true}>
            <CollapsibleBlock title={"Spory sądowe i arbitrażowe, mediacja"}>
              Reprezentujemy klientów na wszystkich etapach postępowań sądowych i arbitrażowych. Analizujemy i przedstawiamy szanse powodzenia sprawy, aby ułatwić podjęcie właściwej decyzji biznesowej.
          </CollapsibleBlock>
            <CollapsibleBlock title={"Doradztwo korporacyjne"}>
              Zapewniamy kompleksową obsługę prawną w zakresie prawa spółek. Dzięki naszemu doświadczeniu pomagamy na wszystkich etapach prowadzenia działalności gospodarczej – przy zakładaniu spółek, w ich codziennym funkcjonowaniu, na końcu działalności. Przygotowujemy opinie i ekspertyzy prawne, które ułatwiają członkom organów podjęcie bezpiecznych decyzji.
          </CollapsibleBlock>
            <CollapsibleBlock title={"Spory korporacyjne"}>
              Zapewniamy obsługę prawną spółkom handlowym, wspólnikom, akcjonariuszom oraz członkom organów na każdym etapie sporu korporacyjnego. Przygotowujemy szczegółową strategię sporu z uwzględnieniem możliwych działań przeciwnika. Reprezentujemy wspólników i akcjonariuszy na zgromadzeniach, doradzamy przy zaskarżaniu uchwał i w negocjacjach w trakcie sporu. Reprezentujemy klientów w postępowaniach sądowych.
          </CollapsibleBlock>
            <CollapsibleBlock title={"Umowy w obrocie gospodarczym"}>
              Doradzamy w zakresie tworzenia i realizacji umów w obrocie gospodarczym, w szczególności w oparciu o przepisy prawa cywilnego, prawa handlowego i prawa autorskiego. Korzystając z naszego doświadczenia pomagamy w negocjowaniu najlepszych warunków biznesowych, przy uwzględnieniu należytego zabezpieczania interesów prawnych klienta.
          </CollapsibleBlock>
            <CollapsibleBlock title={"Sprawy pracownicze i ubezpieczeń społecznych"}>
              Świadczymy usługi z zakresu prawa pracy i ubezpieczeń społecznych na rzecz pracodawców i pracowników. Doradzamy w sprawach związanych z zatrudnieniem pracowników w obszarze indywidualnych stosunków pracy. Badamy dokumenty pracownicze i praktyki w sprawach związanych z zatrudnieniem pod kątem ich zgodności z przepisami prawa. Pomagamy w negocjacjach.
          </CollapsibleBlock>
            <CollapsibleBlock title={"Restrukturyzacja i upadłość"}>
              Świadczymy usługi z zakresu restrukturyzacji i upadłości po stronie dłużników
              i wierzycieli. Pomagamy przedsiębiorcom i osobom fizycznym na każdym etapie postępowania restrukturyzacyjnego i upadłościowego. Dopasowujemy właściwe rozwiązania prawne do potrzeb biznesowych naszych klientów. Reprezentujemy klientów przed sądami upadłościowymi.
          </CollapsibleBlock>
          </CollapsibleBlocksWrapper>
        </ColBorder>
      </StandardBlock>
    </div>
  )
}

export default OfferHero;