/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
// import GoogleMapReact from 'google-map-react';
// import { mapStylesJson } from './mapsStyle';

// const AnyReactComponent = ({ text }: any) => <div className="markerWrapper">
//   <div className="marker">
//     <div className="marker__field">
//       ul. Żurawia 32/34 lok. 28<br />00-515 Warszawa
//     </div>
//   </div>
// </div>;

interface Props {
  maxWidth?: number
}

const SimpleMap: React.FC<any> = (props: any) => {

  // const defaultProps = {
  //   center: {
  //     lat: 52.2360134,
  //     lng: 21.0112764
  //   },
  //   zoom: 15
  // };

  return (
    // Important! Always set the container height explicitly
    <div className="map" style={{ width: '100%' }}>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13441.98569933902!2d21.003186129030215!3d52.22836423323315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471eccf2311cb763%3A0x9fae45677bd4b6e!2s%C5%BBurawia%2032%2F34%2C%2005-800%20Warszawa!5e0!3m2!1spl!2spl!4v1736293915592!5m2!1spl!2spl" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      {/* <GoogleMapReact
        // bootstrapURLKeys={{ key: "AIzaSyBleohv4iNVDWKo4k8XhQIBkzqU5fPDrGQ" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={{
          // styles: mapStylesJson,
        }}
      >
        <AnyReactComponent
          lat={52.2360134}
          lng={21.0112764}
        />
      </GoogleMapReact> */}
    </div>
  );
}

export default SimpleMap;